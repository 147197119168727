
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

p {
  font-family: 'Open Sans';
}

h3 {
  font-family: 'Montserrat';
  font-weight: 500;
}
ul{
  font-family: 'Open Sans';

}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #bec7ce !important;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #bec7ce !important;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #bec7ce !important;
  opacity: 1;
}


:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bec7ce !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bec7ce !important;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #bec7ce !important;
}

.form-details {
  text-align: center;
}


.LoginPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.LoginPage__background {
  background-image: url('https://static.wixstatic.com/media/03854e_467491088bd1401b9cc3fce38c45d264~mv2.jpg/v1/fill/w_1349,h_800,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/03854e_467491088bd1401b9cc3fce38c45d264~mv2.jpg');
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: -1;
}

.LoginPage__form {
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1;
  text-align: center;
  width: 450px;
  height: 65%;
  opacity: 0.9;
}

.loginBtn {
  /* width: 200px; */
  background-color: #1e3977;
  color: white;
  padding: 5px 15px;
  border: 1px solid #1e3977;
}

.loginBtn:hover {
  background-color: #5d6780;
  color: white;
  padding: 5px 15px;
  border: 1px solid #1e3977;
}

.logoutBtn {
  background-color: #1e3977;
  color: white;
  padding: 5px 15px;
  border: 1px solid #1e3977;
}

.logoutBtn:hover {
  background-color: #5d6780;
  color: white;
  padding: 5px 15px;
  border: 1px solid #1e3977;
}

.sendBtn {
  background-color: #1e3977;
  color: white;
  padding: 7px 25px;
  border-radius: 7px;
  border: 1px solid #1e3977;
}

.swal2-styled.swal2-confirm {
  background-color: #1e3977;
}